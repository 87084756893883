<template>
  <content-wrapper>
    <template v-slot:top=""><base-go-back /></template>
    <base-form @submit.prevent="handleSubmit">
      <base-form-title>Editar usuario</base-form-title>

      <base-divider text="Información Personal" />
      <base-input
        id="name"
        label="Nombre"
        placeholder="Nombre"
        :errors="nameErrors"
        @blur="$v.model.name.$touch()"
        v-model="model.name"
      />
      <base-input
        id="lastname"
        label="Apellido paterno"
        placeholder="Apellido Paterno"
        :errors="lastnameErrors"
        @blur="$v.model.lastname.$touch()"
        v-model="model.lastname"
      />
      <base-input
        id="surname"
        label="Apellido Materno"
        placeholder="Apellido Materno"
        :errors="surnameErrors"
        @blur="$v.model.surname.$touch()"
        v-model="model.surname"
      />

      <base-divider text="Información del usuario" />
      <base-input-select
        id="role"
        label="Rol de usuario"
        :errors="roleErrors"
        @blur="$v.model.role.$touch()"
        v-model="model.role"
        :options="userRoles"
      />
      <base-form-button-group>
        <base-button
          appendIcon="times-circle"
          bgColor="#FB3640"
          @click="$router.go(-1)"
        >
          Cancelar
        </base-button>
        <base-button
          bgColor="#FF8600"
          appendIcon="save"
          :submit="true"
          :disabled="isInvalidForm || submitLoading"
          :loading="submitLoading"
        >
          Guardar
        </base-button>
      </base-form-button-group>
    </base-form>
  </content-wrapper>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  name: "edit-user",
  data: () => ({
    model: {
      name: "",
      lastname: "",
      surname: "",
      role: "",
    },
    submitLoading: false,
  }),
  methods: {
    handleSubmit() {
      this.submitLoading = true;
      this.$store
        .dispatch("Recruiter/editById", {
          data: this.formatedModel,
          id: this.formatedModel.id,
        })
        .then(({ status }) => {
          if (status === 200) {
            this.$swal.fire({
              icon: "success",
              title: "Editado!",
              text: "El usuario ha sido editado con exito.",
              showClass: {
                popup: "animate__animated animate__zoomIn animate__faster",
              },
              hideClass: {
                popup: "animate__animated animate__zoomOut animate__faster",
              },
            });
            this.$router.go(-1);
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
  },
  mounted() {
    const user = this.data.find((el) => el.id == this.$route.params.userId);
    const userRole = this.userRoles.find(
      (el) => el.name === user.user.roles[0].name
    );

    this.model = {
      id: user.id,
      name: user.name,
      lastname: user.lastname,
      surname: user.surname,
      email: user.user.email,
      companyId: user.company.id,
      username: user.user.username,
      role: userRole.value,
    };
  },
  computed: {
    ...mapGetters("Recruiter", ["data", "userRoles"]),
    formatedModel() {
      let model = {};
      for (const el in this.model) {
        if (el === "role") {
          model[el] = [this.model[el]];
        } else {
          model[el] = this.model[el];
        }
      }
      return model;
    },
    isInvalidForm() {
      let invalid = this.$v.model.$invalid;
      return invalid;
    },
    rutErrors() {
      const errors = [];
      if (!this.$v.model.rut.$dirty) return "";
      !this.$v.model.rut.required && errors.push("Campo requerido");
      return errors[0];
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.model.name.$dirty) return "";
      !this.$v.model.name.required && errors.push("Campo requerido");
      return errors[0];
    },
    lastnameErrors() {
      const errors = [];
      if (!this.$v.model.lastname.$dirty) return "";
      !this.$v.model.lastname.required && errors.push("Campo requerido");
      return errors[0];
    },
    surnameErrors() {
      const errors = [];
      if (!this.$v.model.surname.$dirty) return "";
      !this.$v.model.surname.required && errors.push("Campo requerido");
      return errors[0];
    },
    roleErrors() {
      const errors = [];
      if (!this.$v.model.role.$dirty) return "";
      !this.$v.model.role.required && errors.push("Campo requerido");
      return errors[0];
    },
  },
  mixins: [validationMixin],
  validations: {
    model: {
      name: { required },
      lastname: { required },
      surname: { required },
      role: { required },
    },
  },
};
</script>
